import styled from 'styled-components';

const ContentWrapper = styled.div`
  width: 80%;
  margin: auto;
  max-width: 1100px;
  overflow: visible;
  @media only screen and (max-width: 748px) {
    width: 100%;
  }
`;

export const LeftContent = styled.div`
  background-color: #eaf5e8;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: calc(100vh - 100px);
  @media only screen and (max-width: 748px) {
    padding-top: 0px;
    padding-bottom: 30px;
    width: 100%;
    -webkit-flex: 0;
    flex-shrink: 0;
  }
`;

export const RightContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #cae2c3;
  border: 2px solid #eaf5e8;
  height: calc(100vh - 100px);

  @media only screen and (max-width: 748px) {
    width: 100%;
    -webkit-flex: 0;
    flex-shrink: 0;
    padding-top: 30px;
  }
`;

export default ContentWrapper;
