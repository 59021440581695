import React, { useContext } from 'react';
import styled from 'styled-components';

import Logo from '../images/logo.png';
import ContentWrapper from './ContentWrapper';
import { D } from '@dayetopia/library';
import { navigate } from 'gatsby';

export default function Footer() {

  function navigatePrivacy() {
    navigate('/privacy-policy');
  }
  
  function navigateTerms() {
    navigate('/terms');
  }

  return (
    <Container>
      <LayoutStyled>
        <FlexContainer>
          {' '}
          <img alt="logo" style={{ width: '120px' }} src={Logo}></img>
          <PStyled>
            The Biscuit Factory
            <br />
            100 Drummond Str.
            <br />
            Bermondsey, London <br />
            SE16 4DG, UK
          </PStyled>
        </FlexContainer>
        <FlexContainer>
          <ButtonStyled
            onClick={() => navigateTerms()}
            target="_blank"
          >
            Our terms
          </ButtonStyled>
          <ButtonStyled
            onClick={() => navigatePrivacy()}
            target="_blank"
          >
            Our privacy policy
          </ButtonStyled>
        </FlexContainer>
      </LayoutStyled>
    </Container>
  );
}

const FlexContainer = styled.div`
  display: flex;
  width: 320px;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  
  @media only screen and (max-width: 500px) {
    margin: auto;
    flex-direction: column;
    margin-bottom: 40px;
  }
`;

const PStyled = styled(D.P)`
  color: #fffae3;
  font-family: Brownstd;
  font-size: 16px;
`;

const ButtonStyled = styled.a`
  height: 60px;
  background-color: #003b1b;
  font-size: 16px;
  line-height: 3;
  font-family: 'BrownStd';
  font-weight: bold;
  letter-spacing: 1px;
  color: #fffbe5;
  padding: 2px 30px 0 30px;
  flex-shrink: 0;
  margin-left: 20px;
  transition: background-color 200ms ease-out;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 59, 27, 0.9);
  }
  border: 2px solid #fffae3;
  text-align: center;
  outline: none;
  @media only screen and (max-width: 500px) {
    margin: 0 0 30px 0;
    width: 80%;
  }
`;
const LayoutStyled = styled(ContentWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 30px;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 80px 0;
  background-color: #01341a;
  @media only screen and (max-width: 500px) {
    padding-bottom: 30px;
  }
`;
