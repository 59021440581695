import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { D } from '@dayetopia/library';
import Footer from '../components/Footer';

export default function Home() {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (typeof document !== `undefined`) {
      const body = document.body
      if (body.classList.contains("no-js-loaded")) {
        setLoaded(false)
      } else {
        setLoaded(true)
      }
    }
  }, [])

  return (
    <>
      <PageContainer loaded={loaded}>
        {loaded && (
          <div>
            <Content>
              <article id="policy">
                <h2>Privacy Policy</h2>
                <section id="privacy-notice">
                  <h4>What does this Privacy Notice cover?</h4>{" "}
                  <p>
                    This Privacy Notice describes how DAYE LTD ("we", "us",
                    "Daye") will make use of your personal data when you use and
                    interact with our website at{" "}
                    <a href="http://www.your-daye.myshopify.com/">
                      www.yourdaye.com
                    </a>{" "}
                    and our other online channels ("Site"). &nbsp;We are the data
                    controller for the purposes of UK and European data protection
                    laws.{" "}
                  </p>
                  <p>
                    It also describes your data protection rights, including a
                    right to object to some of the processing which we carry out
                    and where we rely on consent, a right to withdraw your
                    consent. More information about your rights, and how to
                    exercise them, is set out in the “What rights do I have?”
                    section.{" "}
                  </p>
                  <p>
                    We may also provide you with additional information when we
                    collect personal data where we feel it would be helpful to
                    provide relevant and timely information.
                  </p>{" "}
                </section>
                <section id="personal-data">
                  <h4>What personal data do we collect? </h4>{" "}
                  <p>
                    We
                    <em>
                      <strong> </strong>
                    </em>
                    collect and process personal data about you when you interact
                    with us and our Sites, and when you order something from us
                    (for example, a sample product) or join our subscription
                    service. This includes:{" "}
                  </p>
                  <ul>
                    <li>
                      your name and contact information (including email address
                      and phone number); &nbsp;
                    </li>
                    <li>username and password;</li>
                    <li>
                      the sample you’ve requested, products you've ordered, or the
                      subscription package you've chosen (if you've opted for a
                      subscription, we will have information about your menstrual
                      cycle such as the frequency of your period and your
                      preferred products);
                    </li>
                    <li>
                      your payment and delivery details, including billing and
                      delivery addresses, transaction history and credit card
                      details;
                    </li>
                    <li>
                      any communications that we have with you (for example, your
                      interactions with customer services or information you
                      provide to us about your lifestyle, health or periods
                      through a survey or when you visit our social media pages,
                      blogs or forums); and
                    </li>
                    <li>
                      your marketing preferences, including any consents you have
                      given us.
                    </li>
                  </ul>
                  <p>
                    We also automatically collect the following information when
                    you visit our Sites:
                  </p>
                  <ul>
                    <li>
                      technical information, including your device’s IP address,
                      browser type and version, time zone setting, browser plug-in
                      types and versions, operating system, unique device
                      identifiers and advertising identifiers; and
                    </li>
                    <li>
                      information about your visit, including the URL clickstream
                      to, through and from our Sites (including date and time);
                      products you viewed or searched for, the content (and any
                      ads) that you view or interact with, page response times,
                      download errors, length of visits to certain pages, page
                      interaction information (such as scrolling, clicks, and
                      mouse-overs), and methods used to browse away from the page.
                    </li>
                  </ul>
                  <p>
                    Some of this personal data is collected using cookies and
                    related technologies, and often provided by third party
                    providers such as Google Analytics. &nbsp;To learn more,
                    please see our Cookies Notice.
                  </p>
                  <p>
                    We do not knowingly collect personal data from children under
                    the age of 13.
                  </p>
                  <p></p>{" "}
                </section>
                <section id="data-use">
                  <h4>
                    How do we use this personal data, and what is the legal basis
                    for this use?{" "}
                  </h4>{" "}
                  <p>We process this personal data for the following purposes:</p>
                  <ul>
                    <li>
                      To fulfil a contract, or take steps linked to a contract:
                      this is relevant where you request a sample, purchase
                      products from us, sign up to our subscription service, or
                      enter a competition we run. In particular we will: Use your
                      name and address to send you your box; use your email
                      address to notify you when we send your box; use your email
                      address to notify you of changes you have made to your
                      subscription.
                    </li>
                    <li>
                      As required by us to conduct our business and pursue our
                      legitimate interests.
                    </li>
                    <li>
                      Where you give us consent, we will: Send you marketing
                      information that is designed to inform you of the specifics
                      of our products and services.
                    </li>
                    <li>For purposes which are required by law. </li>
                    <li>
                      respond to requests by government or law enforcement
                      authorities (for example, where they are conducting an
                      investigation); and
                    </li>
                    <li>investigate issues of product liability.</li>
                  </ul>
                  <p></p>
                  <p>
                    <strong>
                      Withdrawing consent or otherwise objecting to direct
                      marketing
                    </strong>
                  </p>
                  <p>
                    Wherever we rely on your consent, you will always be able to
                    withdraw that consent, although we may have other legal
                    grounds for processing your personal data for other purposes,
                    such as those set out above. In some cases, we are able to
                    send you direct marketing without your consent, where we rely
                    on our legitimate interests. &nbsp;You have an absolute right
                    to opt-out of direct marketing, or profiling we carry out for
                    direct marketing, at any time. You can do this by following
                    the instructions in the communication where this is an
                    electronic message, or by contacting us using the details set
                    out below. Please note that this will not stop you from
                    receiving service messages (i.e. non-marketing communications,
                    such as e-mail updates on your order status or notifications
                    about your account activities) from us.{" "}
                  </p>
                  <p>
                    Who will we share this personal data with, where and when?
                  </p>
                  <p>We will share your personal data with:</p>
                  <ul>
                    <li>
                      authorised supply, delivery and fulfilment companies to
                      process and complete your subscription;
                    </li>
                    <li>
                      banks and our payment services provider (Recurly and Adyen)
                      for the purpose of processing transactions and checking for
                      payment card fraud;
                    </li>
                    <li>
                      legal advisors, accountants, auditors and other professional
                      advisors;
                    </li>
                    <li>
                      third parties, where we have your permission to do so (e.g.
                      social networks providers);Your personal data will become
                      subject to the privacy policies of those third parties when
                      your personal data is shared with them;
                    </li>
                    <li>
                      government authorities, court, regulatory authority and/or
                      law enforcement officials if required for the purposes
                      above, if mandated by law or if required for the legal
                      protection of our legitimate interests in compliance with
                      applicable laws. This includes exchanging information with
                      other companies and organisations for the purposes of fraud
                      protection and credit risk reduction; and
                    </li>
                    <li>
                      third party service providers, who will process it on our
                      behalf for the purposes identified above. In particular, we
                      use third party technology and platform providers for
                      website development, hosting and maintenance, website live
                      chat support, surveys and consumer research purposes,
                      payment processing and fraud checking, and email service
                      delivery.{" "}
                    </li>
                  </ul>
                  <p>
                    We also share information with third parties including
                    advertising, social media and search engine partners:
                    <strong> </strong>We aggregate your personal data with the
                    information of other customers, creating a dataset of
                    information about the usage of our Sites, purchase of our
                    products and services, and other general, grouped information
                    about our customers. Although this dataset is aggregated and
                    anonymised, meaning it cannot directly identify you as an
                    individual, it provides a valuable insight into the use of our
                    Sites and we will share it with select third parties.{" "}
                  </p>
                  <p>
                    We also transfer personal data about you to ad technology
                    providers and our social media and search engine partners
                    (including Facebook, Google, Twitter and Instagram) so that
                    they may recognize your devices and deliver interest based
                    content and advertisements. The information can include your
                    name, postal address, email, device ID, or other identifier in
                    encrypted form. The providers often process the information in
                    hashed or de-identified form. These providers can collect
                    additional information from you, such as your IP address and
                    information about your browser or operating system; combine
                    information about you with information from other companies in
                    data sharing cooperatives in which we participate; and may
                    place or recognize their own unique cookie on your browser.
                    The third parties that generate these cookies have their own
                    privacy policies and we have no access to read or write these
                    cookies. For more information about how to opt out of targeted
                    advertising, please see our Cookies Notice.
                  </p>
                  <p>
                    In the event that the business is sold or integrated with
                    another business, your details will be disclosed to our
                    advisers and any prospective purchaser’s adviser and will be
                    passed to the new owners of the business.{" "}
                  </p>
                  <p>
                    We transfer personal data outside the UK and the European
                    Economic Area (‘EEA’) where necessary for the purposes
                    explained in this Privacy Notice.{" "}
                  </p>
                  <p>
                    Where personal data is transferred outside the UK or EEA, and
                    where this is to an organization in a country that is not
                    subject to an adequacy decision by the EU Commission or
                    adequacy determined in another valid method under applicable
                    data protection legislation, personal data is adequately
                    protected by EU Commission approved standard contractual
                    clauses, an appropriate Privacy Shield certification or
                    Binding Corporate Rules. A copy of the relevant mechanism can
                    be provided for your review on request to hello@yourdaye.com.
                  </p>
                  <p></p>{" "}
                </section>
                <section id="security">
                  <h4>Security</h4>{" "}
                  <p>
                    We are committed to ensuring that your personal data is
                    secure. In order to prevent unauthorised access or disclosure,
                    we have put in place suitable physical, electronic and
                    managerial procedures to safeguard and secure the personal
                    data we collect online. We use ‘https’ technology to secure
                    access to all areas of our Site. Access to your data is
                    password-protected, and sensitive data such as payment card
                    information is held securely by our 3rd party payment
                    providers, and tokenized to ensure it is protected. We ensure
                    that our systems are regularly monitored for possible
                    vulnerabilities and attacks.
                  </p>
                  <p></p>
                  <p>
                    <strong>Links to Other Websites</strong>
                  </p>
                  <p>
                    Our Sites may contain links to other websites of interest.
                    However, once you have used these links to leave our Sites,
                    you should note that we do not have any control over that
                    other website. Therefore, we cannot be responsible for the
                    protection and privacy of any information which you provide
                    whilst visiting such sites and such sites are not governed by
                    this privacy notice. You should exercise caution and look at
                    the privacy statement applicable to the website in question.
                  </p>
                  <p></p>
                  <p>
                    <strong>What rights do I have?</strong>
                  </p>
                  <p>
                    In addition to rights to withdraw your consent or object to
                    direct marketing (as outlined above, you have the right to{" "}
                    <strong>ask us for a copy </strong>of your personal data; to{" "}
                    <strong>correct</strong>, <strong>delete</strong> or{" "}
                    <strong>restrict</strong> (stop any active) processing of your
                    personal data; and to{" "}
                    <strong>
                      obtain the personal data you provide to us for a contract or
                      with your consent in a structured, machine readable format
                    </strong>
                    , and to ask us to{" "}
                    <strong>share (port) this data to another controller.</strong>
                  </p>
                  <p>
                    In addition, you can <strong>object to the processing</strong>{" "}
                    of your personal data in some circumstances (in particular,
                    where we don’t have to process the data to meet a contractual
                    or other legal requirement, or where we are using the data for
                    direct marketing).{" "}
                  </p>
                  <p>
                    These <strong>rights may be limited</strong>, for example if
                    fulfilling your request would reveal personal data about
                    another person, where they would infringe the rights of a
                    third party (including our rights) or if you ask us to delete
                    personal data which we are required by law to keep or have
                    compelling legitimate interests in keeping. Relevant
                    exemptions are included in both the GDPR and in the Data
                    Protection Act 2018 (or any subsequent legislation). We will
                    inform you of relevant exemptions we rely upon when responding
                    to any request you make.
                  </p>
                  <p>
                    To exercise any of these rights, or to obtain other
                    information, such as a copy of a legitimate interests
                    balancing test, you can get in touch with us using the details
                    set out below. You can also deactivate your account and
                    request that we delete your personal data by contacting us
                    directly on hello@yourdaye.com. If you have unresolved
                    concerns, you have the <strong>right to complain</strong> to
                    the Information Commissioner’s Office in the UK or any EU data
                    protection authority where you live, work or where you believe
                    a breach may have occurred.{" "}
                  </p>
                  <p>
                    In order to provide you with a sample, products which you've
                    purchased, or our subscription service, you must provide us
                    with your name, address, email address user name and password
                    and billing details. If this information is not provided, then
                    we cannot provide the subscription service. All other
                    provision of your information is optional. A failure to
                    provide this information may mean that other functionalities
                    and services are available such as our product
                    recommendations.
                  </p>
                  <p></p>
                  <p>
                    <strong>How long will you retain my personal data?</strong>
                  </p>
                  <p>
                    We’ll only keep and process your personal data for as long as
                    is necessary for the purpose for which we collected it in the
                    first place.{" "}
                  </p>
                  <p></p>{" "}
                </section>
                <section id="cookie-policy">
                  <h4>Cookies Notice</h4>{" "}
                  <p>
                    We use cookies or similar technologies (such as web beacons or
                    log files) on our Sites and in our communications. We do this
                    to understand your use of our services, improve your user
                    experience and enable personalized features and content;
                    measure your interaction with our communications, optimize our
                    advertising and marketing and to enable third party
                    advertising companies to assist us in serving ads specific to
                    your interests across the Internet. Cookies are text files
                    containing small amounts of information which are downloaded
                    to your device when you visit a site. Cookies are then sent
                    back to the originating site on each subsequent visit, or to
                    another site that recognises that cookie. You can find more
                    information about cookies at:{" "}
                    <a href="http://www.allaboutcookies.org">
                      www.allaboutcookies.org
                    </a>{" "}
                  </p>
                  <p>We use the following types of cookies:</p>
                  <ul>
                    <li>
                      <strong>Strictly Necessary Cookies</strong>: These cookies
                      are essential in order to enable you to move around the
                      Sites and use their features, such as accessing secure areas
                      of the Sites or keeping selected products in your shopping
                      cart. Without these cookies, services like enabling
                      appropriate content based your type of device cannot be
                      provided.
                    </li>
                    <li>
                      <strong>Performance/Analytical Cookies</strong>: These
                      cookies collect information about how visitors use the
                      Sites, for instance which pages visitors go to most often,
                      and if they get error messages from web pages. These cookies
                      don’t collect information that directly identifies a
                      visitor. The information these cookies collect is aggregated
                      and used to improve how the Sites works. We use Google
                      Analytics, run by Google Inc for this purpose.
                    </li>
                    <li>
                      <strong>Functionality Cookies</strong>: These cookies allow
                      the Sites to remember choices you make (such as your
                      languageor the region you are in) and provide enhanced, more
                      personal features. These cookies can also be used to
                      remember changes you have made to text size, fonts and other
                      parts of web pages that you can customise. They may also be
                      used to provide services you have asked for such as watching
                      a video or commenting on a blog. These cookies cannot track
                      your browsing activity on other websites.
                    </li>
                    <li>
                      <strong>Social Media Cookies</strong>: These cookies are
                      used when you share information using a social media sharing
                      button on the Sites or log in to our Sites using your social
                      media details. The social network will record that you have
                      done this. This information may be linked to
                      targeting/advertising activities. We do not control how
                      these social networks use cookies. Interactions with these
                      features are governed by the privacy policy of the company
                      providing it.
                    </li>
                    <li>
                      <strong>Targeting/Advertising Cookies:</strong> These
                      cookies are used to deliver adverts more relevant to you and
                      your interests both on our Sites and on our partner
                      websites. We carry out targeted advertising on our existing
                      customer base as well as target other individuals who we
                      think will be interested in our products and services. They
                      are also used to limit the number of times you see an
                      advertisement as well as help measure the effectiveness of
                      the advertising campaigns. They are usually placed by
                      advertising networks with our permission. They remember that
                      you have visited a website and this information is shared
                      with other organisations such as advertisers. Advertisers
                      and other third parties (including the ad networks,
                      ad-serving companies, and other service providers they may
                      use) may assume that users who interact with or click on a
                      personalized ad or content are part of the group that the ad
                      or content is directed towards.
                    </li>
                  </ul>
                  <p>
                    In order to place cookies on your device, we require your
                    consent, which we will obtain when you first visit our Sites.
                    If you don’t want to allow cookies at all, or only want to
                    allow the use of certain cookies, refer to your browser
                    settings. You can also use your browser settings to withdraw
                    your consent to our use of cookies at any time and delete
                    cookies that have already been set. You should consult with
                    your browser's provider/manufacturer if you have any questions
                    regarding disabling cookies. Note that by rejecting or
                    disabling certain categories of cookies, you may be prevented
                    from accessing some features of our Sites or certain content
                    or functionality may not be available.
                  </p>
                  <p>
                    To opt out of Google Analytics, please visit:{" "}
                    <a href="https://tools.google.com/dlpage/gaoptout">
                      https://tools.google.com/dlpage/gaoptout
                    </a>
                  </p>
                  <p></p>
                  <p>
                    If you'd like to opt out of third party cookies relating to
                    behavioural advertising, please go to{" "}
                    <a href="http://www.youronlinechoices.eu">
                      www.youronlinechoices.eu
                    </a>
                    . Opting out does not mean you will no longer receive online
                    advertising. It does mean that the company or companies from
                    which you opted out will no longer deliver ads tailored to
                    your web preferences and usage patterns.
                  </p>
                  <p>
                    <strong>Web server logs and web beacons</strong>
                  </p>
                  <p>
                    In conjunction with obtaining information through cookies, our
                    web servers may log details such as your operating system
                    type, browser type, domain, and other system settings, as well
                    as the language your system uses and the country and time zone
                    in which your device is located. The web server logs also may
                    record information such as the address of the web page that
                    linked you to our site and the IP address of the device you
                    use to connect to the Internet. This information helps us to
                    troubleshoot errors, improve performance and maintain the
                    security of our Sites. To control which web servers collect
                    this information, we may place tags on our web pages called
                    “web beacons.” These are computer instructions that link web
                    pages to particular web servers and their cookies. We may also
                    use cookies and similar technologies (such as web beacons) to
                    allow us to tell if an email we have sent you has been opened
                    and acted upon and whether our mailing tools are working
                    correctly or, to measure performance and to provide content
                    and ads that are more relevant to you.
                  </p>
                  <p>
                    Because web beacons are the same as any other content request
                    included in the recipe for a web page, you cannot opt out or
                    refuse them. However, you may be able to disable web beacons
                    in email messages by not downloading images contained in
                    messages you receive (this feature varies depending on the
                    email software used on your personal computer). However, doing
                    this may not always disable a web beacon or other similar
                    technologies in the email message due to specific email
                    software capabilities. For more information about this, please
                    refer to the information provided by your email software or
                    service provider. Web beacons may also be rendered ineffective
                    in some circumstances by opting out of cookies or amending
                    your cookie settings in your browser.
                  </p>{" "}
                </section>
                <section id="contact">
                  <h4>How do I get in touch with you? </h4>{" "}
                  <p>
                  We hope that we can satisfy queries you may have about the way we process your personal data. If you have any concerns about how we process your personal data, or would like to opt out of direct marketing, you can get in touch at hello@yourdaye.com or by writing to Daye, Cocoa Building Suite 301, The Biscuit Factory, 100 Drummond road, Bermondsey, London SE16 4DG.{" "}
                  </p>{" "}
                </section>
              </article>
            </Content>
          </div>
        )}
      </PageContainer>
      <Footer />
    </>
  )
}

interface PageContainer {
  loaded: boolean
}

const PageContainer = styled.div`
  transition: opacity 0.5s ease-in, visibility 0.3s ease-in;
  opacity: ${({ loaded }: PageContainer) => (loaded ? 1 : 0)};
  visibility: ${({ loaded }: PageContainer) => (loaded ? "visible" : "hidden")};
`

const Content = styled.div`
  padding-top: 140px;
  margin: 0 auto;
  max-width: 1000px;
  width: 90vw;
  font-family: "BrownStd";

  section {
    padding-bottom: 60px;
  }

  ul {
    margin: 30px 60px;

    li {
      font-size: 16px;
      line-height: 22px;
      color: rgb(0, 58, 27);
    }
  }

  h2 {
    font-size: 4em;
    margin-bottom: 60px;
    font-family: "Domaine";
    color: rgb(0, 58, 27);
  }

  h4 {
    font-size: 18px;
    line-height: 22px;
    font-family: 'BrownStd';
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 20px;
    color: rgb(0, 58, 27);
  }

  p {
    font-size: 16px;
    line-height: 22px;
    color: rgb(0, 58, 27);
    margin: 0 0 20px;
  }

  a {
    text-decoration: underline;

    &:hover {
      font-weight: bold;
    }
  }
`